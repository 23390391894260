@tailwind base;
@tailwind components;
@tailwind utilities;

.form-input {
   @apply appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
}

.form-input-unsized {
   @apply appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm;
}

.form-select {
   @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

.form-select-unsized {
   @apply block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}

.form-btn {
   @apply text-white font-bold p-2 rounded;
}

.form-label {
   @apply mt-4 mb-2 block text-sm font-medium text-gray-700 text-left;
}

.admin-card {
   @apply h-10 py-6 px-1 hover:bg-gray-100 border-gray-300 border-t block text-left;
}

.edit-btn {
   @apply block py-3 px-4 bg-blue-800 hover:bg-blue-600 focus:outline-none text-white p-2 rounded-md shadow-sm;
}

.basic-btn {
   @apply block py-3 px-4 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none;
}

.delete-btn {
   @apply block py-3 px-4 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-gray-50 focus:outline-none;
}

.delete-btn-secondary {
   @apply block py-3 px-4 border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md bg-red-700 text-white hover:bg-red-500 focus:outline-none;
}

.delete-btn-sm {
   @apply block p-2 border border-gray-300 shadow-sm  leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-gray-50 focus:outline-none;
}

.basic-btn-sm {
   @apply block p-2 border border-gray-300 shadow-sm  leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none;
}

.link {
   @apply text-blue-600 hover:text-blue-800 cursor-pointer;
}

.action-btn {
   @apply inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none;
}

.basic-table {
   @apply rounded-t-lg w-full bg-gray-200 text-gray-800;
}

.table-row-header {
   @apply text-left border-b-2 border-gray-300;
}

.table-row {
   @apply text-left bg-gray-100 border-b border-gray-200;
}

.table-cell {
   @apply px-4 py-3;
}

.pdf-page-container {
   border: 1px solid lightgray;
   box-shadow: lightgray 0 0 7px 1px;
}

.unreset {
   all: initial;
   contain: paint style;

   input,
   textarea,
   select {
      padding: revert;
   }

   a {
      color: revert;
      text-decoration: revert;
   }

   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      font-weight: revert;
      font-size: revert;
   }
}

.svc-creator {
   /* SurveyJS Creator */
   --base-unit: 6px;
}

.sd-container-modern {
   /* SurveyJS modern theme */
   font-family:
      Open Sans,
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif; /*This is the list used in the body of the survey*/

   .sd-progress-buttons__list {
      overflow-x: scroll;
      li {
         margin: 0px 10px;
      }
   }
}

.dir-rtl {
   direction: rtl;
   .sd-element__num {
      float: right;
      margin-right: calc(var(--base-unit, 8px) * -4);
   }
   .sd-item__control-label {
      margin-right: calc(1 * var(--base-unit, 8px));
   }
   .sd-input {
      text-align: initial;
   }
}
.dir-ltr {
   direction: ltr;
}
